class AlertController {
  constructor() {
    this.ALERT_PORTAL_ID = '_CDS_ALERT_PORTAL_ID';
    this.alertPortal = document.querySelector(`#${this.ALERT_PORTAL_ID}`);
    if (!this.alertPortal) {
      const body = document.querySelector('body');
      this.alertPortal = document.createElement('div');
      this.alertPortal.setAttribute('id', this.ALERT_PORTAL_ID);
      body.append(this.alertPortal);
    }
  }
  showAlertConfirm(itemConfig) {
    return new Promise(resolve => {
      const item = this.createItem(itemConfig);
      this.alertPortal.appendChild(item);
      item.addEventListener('cdsConfirmChanged', e => resolve(e.detail));
    });
  }
  showAlert(itemConfig) {
    const item = this.createItem(itemConfig);
    this.alertPortal.appendChild(item);
    return item;
  }
  createItem(itemConfig) {
    const item = document.createElement('cds-alert');
    const alertConfig = Object.assign(this.getDefaultConfig(), itemConfig);
    item.setAttribute('type', alertConfig.type);
    item.setAttribute('btn-confirm-text', alertConfig.confirmButtonText);
    item.setAttribute('prevent-close', alertConfig.preventClose.toString());
    item.setAttribute('reverse-actions', alertConfig.reverseButtons.toString());
    item.setAttribute('with-button-close', alertConfig.withButtonClose.toString());
    if (alertConfig.timer) {
      item.setAttribute('timer', alertConfig.timer.toString());
    }
    if (alertConfig.cancelButtonText) {
      item.setAttribute('btn-cancel-text', alertConfig.cancelButtonText);
    }
    if (alertConfig.title) {
      item.setAttribute('alert-title', alertConfig.title);
    }
    if (alertConfig.html || alertConfig.text) {
      if (alertConfig.html) {
        item.setAttribute('html', alertConfig.html);
      } else {
        item.setAttribute('text', alertConfig.text);
      }
    }
    return item;
  }
  getDefaultConfig() {
    return {
      type: 'success',
      confirmButtonText: 'Aceptar',
      preventClose: false,
      reverseButtons: false
    };
  }
}
export { AlertController as A };